import React from "react";
import { useTranslation } from "react-i18next";
import Marquee from "../components/magicui/marquee";

import img0 from "../assets/avatars/0.png";
import img1 from "../assets/avatars/1.png";
import img2 from "../assets/avatars/2.png";
import img3 from "../assets/avatars/3.png";
import img4 from "../assets/avatars/4.png";
import img5 from "../assets/avatars/5.png";
import img6 from "../assets/avatars/6.png";

const reviews = [
  {
    name: "Amit",
    username: "@amit007",
    bodyKey: "review1",
    img: img0
  },
  {
    name: "Priya",
    username: "@priya_star",
    bodyKey: "review2",
    img: img1
  },
  {
    name: "Rahul",
    username: "@rahul_92",
    bodyKey: "review3",
    img: img2
  },
  {
    name: "Sneha",
    username: "@sneha123",
    bodyKey: "review4",
    img: img3
  },
  {
    name: "Vikram",
    username: "@vikram_king",
    bodyKey: "review5",
    img: img4
  },
  {
    name: "Anjali",
    username: "@anjali_89",
    bodyKey: "review6",
    img: img5
  },
  {
    name: "Rohit",
    username: "@rohit_rocks",
    bodyKey: "review7",
    img: img6
  }
];

const firstRow = reviews.slice(0, reviews.length / 2);

const ReviewCard = ({
  img,
  name,
  username,
  bodyKey
}: {
  img: string;
  name: string;
  username: string;
  bodyKey: string;
}) => {
  const { t } = useTranslation();

  return (
    <figure className="bg-black p-5 rounded-2xl bg-opacity-50 w-[300px] bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-70">
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium text-white/80">
            {name}
          </figcaption>
          <p className="text-xs font-medium text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-md text-white/80">
        {t(bodyKey)}
      </blockquote>
    </figure>
  );
};

export function MarqueeDemo() {
  return (
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background w-[800px] mt-5">
      <Marquee className="[--duration:20s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>
    </div>
  );
}
