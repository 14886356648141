"use client";

import Particles from "./magicui/particles";
import { isMobile } from "react-device-detect";

const ParticlesBackground = () => {
  return (
    <div className="w-full h-full absolute">
      <Particles
        className="absolute inset-0 w-full h-full z-0"
        quantity={isMobile ? 500 : 1000}
        ease={20}
        color="#ffa89e"
        refresh
      />
    </div>
  );
};

export default ParticlesBackground;
