import { VelocityScroll } from "./magicui/scroll-based-velocity";
import { useTranslation } from "react-i18next";

export function VelocityText() {
  const { t } = useTranslation();

  return (
    <VelocityScroll
      text={t("velocity")}
      default_velocity={1}
      className="font-display text-center text-4xl font-light tracking-[-0.02em] text-yellow-500 text-opacity-80 drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem]  font-custom"
    />
  );
}
