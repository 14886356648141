// src/components/Popup.tsx

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import qr from "../assets/download.png";
import android from "../assets/android.svg";
import apple from "../assets/apple.svg";

interface PopupProps {
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ onClose }) => {
  const { t } = useTranslation();
  useEffect(() => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="rounded-xl shadow-lg relative transform transition-transform duration-300 ease-out scale-95 opacity-100 animate-fade-in cursor-pointer bg-black bg-opacity-70 p-6">
        <button
          className="absolute top-0 right-2 text-white text-lg font-bold transform transition-transform duration-300 ease-out hover:scale-110 text-6xl"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex items-center justify-center gap-2 my-1 flex-col">
          <span className="text-lg md:text-xl font-bold font-custom text-white">
            {t("downloadAppNow")}
          </span>
          <span className="text-xs font-bold font-custom text-white opacity-70 mb-1">
            {t("scan")}
          </span>
          <img
            src={qr}
            alt="Popup"
            className="w-[200px] bg-white p-2 rounded-xl"
          />
          <div className="flex items-center justify-center gap-2 my-1 opacity-50">
            <img src={android} alt="Big Rummy" className="h-[12px]" />
            <img src={apple} alt="Big Rummy" className="h-[12px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
