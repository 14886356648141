import React from "react";
import { useTheme } from "next-themes";
import { useTranslation } from "react-i18next";
import highValue from "./../assets/high-value.webp";
import instant from "./../assets/instant.webp";
import play from "./../assets/play.webp";
import quick from "./../assets/quick.webp";
import dc from "./../assets/dc.webp";
import legal from "./../assets/legal.webp";
import MagicCard from "./magicui/magic-card";
import BlurFade from "./magicui/blur-fade";

const list = [
  {
    titleKey: "playCashGames",
    subKey: "termsApply",
    asset: highValue
  },
  {
    titleKey: "withdrawWinningsInstantly",
    subKey: "withinMinutes",
    asset: instant
  },
  {
    titleKey: "depositAnytime",
    subKey: "whereverYouAre",
    asset: dc
  },
  {
    titleKey: "quickMatchmaking",
    subKey: "zeroWaitTime",
    asset: quick
  },
  {
    titleKey: "games25Plus",
    subKey: "unlimitedPractices",
    asset: play
  },
  {
    titleKey: "noComplications",
    subKey: "transparentProcess",
    asset: legal
  }
];

export function FeatureSection() {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:w-[1200px] mt-20 mx-5">
      {list.map((item, index) => (
        <BlurFade key={index} delay={0.25 + index * 0.05} inView>
          <MagicCard
            className="cursor-pointer flex flex-col items-center justify-center shadow-2xl text-4xl px-10 py-6 bg-gradient-to-br from-red-500 to-red-800 rounded-2xl border-red-400"
            gradientColor={theme === "dark" ? "#262626" : "#D9D9D955"}
          >
            <img
              src={item.asset}
              alt="Big Rummy"
              className="h-[100px] w-[200px] mx-auto object-cover"
            />
            <h1 className="font-custom font-bold text-2xl text-white w-full text-center mt-4">
              {t(item.titleKey)}
            </h1>
            <h2 className="font-custom text-sm mt-2 text-white w-full text-center">
              {t(item.subKey)}
            </h2>
          </MagicCard>
        </BlurFade>
      ))}
    </div>
  );
}
