import NumberTicker from "./magicui/number-ticker";
import GradualSpacing from "./magicui/gradual-spacing";
import { IconCloudDemo } from "./cloud";
import { useTranslation } from "react-i18next";

const CloudSection = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-5">
      <div className="flex flex-col items-start md:items-start justify-start w-[330px] md:w-[400px]">
        <NumberTicker
          value={18574125}
          className="text-white text-5xl md:text-7xl font-custom text-center"
          suffix="₹"
        />
        <GradualSpacing
          text={t("winningsDispersed")}
          className="text-yellow-500 text-xl font-custom text-center md:text-left"
        />{" "}
      </div>
      <div className="w-[600px] -mt-20 md:w-[800px]">
        <IconCloudDemo />
      </div>
    </div>
  );
};

export default CloudSection;
