import { useEffect } from "react";
import FixedBottomBar from "../components/bottom";
import { FeatureSection } from "../components/cards";
import CloudSection from "../components/cloudSection";
import EarnSection from "../components/earn";
import { HeaderSection } from "../components/header";
import MainSection from "../components/main";
import ParticlesBackground from "../components/particles";
import { ScreenSection } from "../components/screens";
import { VelocityText } from "../components/scrolltext";
import { MarqueeDemo } from "../components/testimonials";
import UpiSection from "../components/upiSection";
import { copyToClipboard } from "../lib/utils";
import Adjust from "@adjustcom/adjust-web-sdk";

import { useSearchParams } from "react-router-dom";

const HomePage = () => {
  const [searchParams] = useSearchParams();

  function trackEvent(inviteCode: string | null) {
    Adjust.waitForWebUUID()
      .then(async (webUUID) => {
        Adjust.trackEvent({
            eventToken: "gbco5d",
            callbackParams: [
              { key: "inviteCode", value: inviteCode || "" },
              { key: "webUUID", value: webUUID || "" },
            ],
          });
      })
      .catch((error) => console.error("API call error:", error));
  }

  useEffect(() => {
    const inviteCode = searchParams.get("invite");
    trackEvent(inviteCode);
    if (inviteCode) {
      copyToClipboard(inviteCode as string);
    }
  }, [searchParams]);

  return (
    <div className="min-h-screen flex items-center bg-gradient-radial flex-col relative overflow-hidden">
      <FixedBottomBar />
      <ParticlesBackground />
      <MainSection />
      <UpiSection />
      <ScreenSection />
      <CloudSection />
      <EarnSection />
      <VelocityText />
      <FeatureSection />
      <MarqueeDemo />
      <div className="mt-40" />
      <HeaderSection />
    </div>
  );
};

export default HomePage;
