import {ReactComponent as UpiSvg} from ".././assets/upi.svg";
import GradualSpacing from "./magicui/gradual-spacing";
import { useTranslation } from "react-i18next";

const UpiSection = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-10 flex items-center justify-center bg-[rgba(99,8,22,0.4)] gap-5 rounded-[12px] p-5">
      <div className="flex flex-col items-start justify-start ">
        <GradualSpacing
          text={t("withdrawWinnings")}
          className="text-white text-xl font-custom text-center"
        />
        <GradualSpacing
          text={t("directlyToBank")}
          className="text-white text-xl font-custom text-center"
        />
      </div>
      <UpiSvg width={70} />
    </div>
  );
};

export default UpiSection;
