import BlurFade from "./magicui/blur-fade";
import sc1 from "./../assets/sc1.png";
import sc2 from "./../assets/sc2.png";
import sc3 from "./../assets/sc3.png";

export function ScreenSection() {
  return (
    <div className="flex justify-center mt-10">
      <div className="w-full max-w-[1400px] p-2">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <BlurFade delay={0.25 + 1 * 0.05} inView>
            <div className="w-full">
              <img src={sc1} alt="Screen" className="w-full h-auto" />
            </div>
          </BlurFade>
          <BlurFade delay={0.25 + 2 * 0.05} inView>
            <div className="w-full">
              <img src={sc2} alt="Screen" className="w-full h-auto" />
            </div>
          </BlurFade>
          <BlurFade delay={0.25 + 3 * 0.05} inView>
            <div className="w-full">
              <img src={sc3} alt="Screen" className="w-full h-auto" />
            </div>
          </BlurFade>
        </div>
      </div>
    </div>
  );
}
