import AlphaVideo from "./alphaVideo";
import bigrw from ".././assets/bigr.webm";
import rayw from ".././assets/ray.webm";
import bigrm from ".././assets/bigrmm-1.mov";
import best from ".././assets/best.png";
import { useTranslation } from "react-i18next";

const MainSection = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center flex-col pt-20">
      <div className="flex items-center justify-center relative z-10 animate-scale">
        <AlphaVideo
          webmSrc={rayw}
          mp4Src={rayw}
          loop={true}
          width="600"
          height="500"
          className="absolute"
        />
        <AlphaVideo
          webmSrc={bigrw}
          mp4Src={bigrm}
          loop={false}
          width="600"
          height="500"
          className="relative"
        />
      </div>
      <h1 className="-mt-6 text-white text-4xl font-bold font-custom z-10">
        {t("playBig")}
      </h1>
      <p className="mt-2 text-white text-xl font-custom z-10 text-center mx-10">
        {t("twentyFiveGames")}
      </p>
      <img
        src={best}
        alt={t("indiasBest")}
        className="object-cover h-20 w-50 mt-5 z-10 mx-auto animate-scale"
      />
    </div>
  );
};

export default MainSection;
